export const staffsizeList = [ //企业规模
  {
    value: 1,
    label_en: "1-50 people",
    label_zh: "1-50人"
  },
  {
    value: 2,
    label_en: "51-100 people",
    label_zh: "51-100人"
  },
  {
    value: 3,
    label_en: "101-200 people",
    label_zh: "101-200人"
  },
  {
    value: 4,
    label_en: "201-500 people",
    label_zh: "201-500人"
  },
  {
    value: 5,
    label_en: "501-1000 people",
    label_zh: "501-1000人"
  },
  {
    value: 6,
    label_en: "More than 1001 people",
    label_zh: "1001人及以上"
  }
]

export const levelRequire = [           //学历
  {
    value: 1,
    label_zh: '初中及以下',
    label_en: "Middle School and below"
  },
  {
    value: 2,
    label_zh: '中专/中技',
    label_en: "Technical school"
  },
  {
    value: 3,
    label_zh: '高中',
    label_en: " High School"
  },
  {
    value: 4,
    label_zh: '大专',
    label_en: "High School"
  },
  {
    value: 5,
    label_zh: '本科',
    label_en: "Bachelor "
  },
  {
    value: 6,
    label_zh: '硕士',
    label_en: "Master"
  },
  {
    value: 7,
    label_zh: '博士',
    label_en: "Doctor"
  },
  {
    value: 8,
    label_zh: '不限',
    label_en: "Unlimited"
  }
]
export const positionTypeList = [           //职位类型
  {
    value: 1,
    label_zh: '销售',
    label_en: 'Sales',
  },
  {
    value: 2,
    label_zh: '操作/报关/单证',
    label_en: 'Operation',
  },
  {
    value: 3,
    label_zh: '客服',
    label_en: 'Customer Service',
  },
  {
    value: 4,
    label_zh: '市场/商务',
    label_en: 'Marketing',
  },
  {
    value: 5,
    label_zh: '仓储/运输/配送',
    label_en: 'Warehouse&Delivery',
  },
  {
    value: 6,
    label_zh: '财务',
    label_en: 'Finance',
  },
  {
    value: 7,
    label_zh: '人力资源',
    label_en: ' Human Resource',
  },
  {
    value: 8,
    label_zh: '行政/后勤/文秘',
    label_en: 'Administration/Secretary',
  },
  {
    value: 9,
    label_zh: '采购/外贸',
    label_en: 'Perchase/ Trade',
  },
  {
    value: 10,
    label_zh: '高级管理',
    label_en: 'Senior Management',
  },
  {
    value: 11,
    label_zh: '其他',
    label_en: 'Other',
  }
]
export const wages = [
  {
    value: 1,
    label_en: '1k',
    label_zh: '1k',
  },
  {
    value: 2,
    label_en: '2k',
    label_zh: '2k',
  },
  {
    value: 3,
    label_en: '3k',
    label_zh: '3k',
  },
  {
    value: 4,
    label_en: '4k',
    label_zh: '4k',
  },
  {
    value: 5,
    label_en: '5k',
    label_zh: '5k',
  },
  {
    value: 6,
    label_en: '6k',
    label_zh: '6k',
  },
  {
    value: 7,
    label_en: '7k',
    label_zh: '7k',
  },
  {
    value: 8,
    label_en: '8k',
    label_zh: '8k',
  },
  {
    value: 9,
    label_en: '9k',
    label_zh: '9k',
  },
  {
    value: 10,
    label_en: '10k',
    label_zh: '10k',
  },
  {
    value: 11,
    label_en: '11k',
    label_zh: '11k',
  },
  {
    value: 12,
    label_en: '12k',
    label_zh: '12k',
  },
  {
    value: 13,
    label_en: '13k',
    label_zh: '13k',
  },
  {
    value: 14,
    label_en: '14k',
    label_zh: '14k',
  },
  {
    value: 15,
    label_en: '15k',
    label_zh: '15k',
  },
  {
    value: 16,
    label_en: '16k',
    label_zh: '16k',
  },
  {
    value: 17,
    label_en: '17k',
    label_zh: '17k',
  },
  {
    value: 18,
    label_en: '18k',
    label_zh: '18k',
  },
  {
    value: 19,
    label_en: '19k',
    label_zh: '19k',
  },
  {
    value: 20,
    label_en: '20k',
    label_zh: '20k',
  }
]

export const schoolType = [
  {
    value: 1,
    label_en: 'Full-time',
    label_zh: '全日制',
  },
  {
    value: 2,
    label_en: 'Part-time',
    label_zh: '非全日制',
  }
]
export const jobType = [
  {
    value: 1,
    label_en: 'Full-time Job',
    label_zh: '全职',
  },
  {
    value: 2,
    label_en: 'Part-time Job',
    label_zh: '兼职',
  }
]

export const experience = [
  {
    value: '1',
    label_zh: '不限',
    label_en:"Unlimited",
  },
  {
    value: '2',
    label_zh: '在校/应届',
    label_en:"在校/应届",
  },
  {
    value: '3',
    label_zh: '1年以内',
    label_en:"Within a year",
  },
  {
    value: '4',
    label_zh: '1-3年',
    label_en:"1-3years",
  },
  {
    value: '5',
    label_zh: '3-5年',
    label_en:"3-5 years",
  },
  {
    value: '6',
    label_zh: '5-10年',
    label_en:"5-10 years",
  },
  {
    value: '7',
    label_zh: '10年以上',
    label_en:"more than 10 years",
  }
]
