<template>
  <div class="applyFor">
    <div class="hint">
      加入企业申请已提交，请耐心等待管理员处理
    </div>
    <div>
      <div class="applyInfoDetail clearfix">
        <div class="item clearfix">
          <div class="key">{{ $t("companyName") }}{{ $t("colon") }}</div>
          <div class="value">
            {{
              userBingInfo.company_name_en
                | priorFormat(userBingInfo.company_name_zh, LOCALE)
            }}
          </div>
        </div>
        <div class="item clearfix">
          <div class="key">{{ $t("country") }}{{ $t("colon") }}</div>
          <div class="value">
            {{ userBingInfo.company_country | countryFormat(LOCALE) }}
          </div>
        </div>
        <div class="item clearfix">
          <div class="key">{{ $t("region") }}{{ $t("colon") }}</div>
          <div class="value">
            {{
              userBingInfo.company_region_en
                | priorFormat(userBingInfo.company_region_zh, LOCALE)
            }}
          </div>
        </div>
        <!-- <div class="item clearfix">
        <div class="key">{{$t('memberCenter.applyTime$')}}</div>
        <div class="value">2020-02-28 17:54:28</div>
      </div> -->
        <div class="item clearfix">
          <div class="key">{{ $t("applyStatus") }}{{ $t("colon") }}</div>
          <div class="value">
            <p v-if="userBingInfo.status == 1">
              {{ $t("applyWait") }}
            </p>
            <p v-if="userBingInfo.status == 3" class="dangerColor">
              {{ $t("applyReject") }}
            </p>
          </div>
        </div>
        <!-- <div class="item clearfix nohidden" v-if="status!=2">
        <div class="key">{{$t('memberCenter.rejectReasons$')}}</div>
        <div class="value">ET is a listed company in China stock market established in Shanghai,</div>
      </div> -->
      </div>
      <div class="modifyBtn">
        <el-button type="primary" size="small" @click="openDialog">{{
          $t("changeCompany")
        }}</el-button>
      </div>
    </div>
    <register
      ref="registerCompany"
      @confirmJoin="confirmJoin"
      @btnQueryChange="btnQueryChange"
    >
      <!-- <div slot="btnRight">查看公司</div> -->
    </register>
  </div>
</template>
<script>
import register from "~/baseComponents/registerCompany";
export default {
  components: { register },
  data() {
    return {
      status: 1,
      userBingInfo: {},
    };
  },
  methods: {
    openDialog() {
      this.$refs.registerCompany.openPanel();
    },
    btnQueryChange(val) {
      // 点击右边插槽按钮发射回来的事件
    },
    async getList(val) {
      try {
        let result = await this.$store.dispatch("baseStore/getCompanyStatus", {
          user_id: this.USER_INFO.id,
        });
        if (result.success) {
          this.userBingInfo = result.data;
          if (val) {
            this.$emit("changeCompany", this.userBingInfo.status);
          }
        }
      } catch (e) {}
    },
    async confirmJoin(val) {
      // this.saveLoading = true;
      let params = {
        user_id: this.USER_INFO.id,
      };
      let _params = Object.assign(params, val.result);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_bindInfo",
          _params
        );
        // this.saveLoading = false;
        if (result.success) {
          this.$ssTip();
          this.getList(true);
          this.$refs.registerCompany.closePanel();
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.saveLoading = false;
        this.$seTip();
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.applyFor {
  padding: 0 12px;
  div,
  p,
  span {
    color: #808080;
  }
  .hint {
    font-weight: bold;
  }
  .applyInfoDetail {
    margin: 30px;
    .item {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      &.noHidden {
        height: auto;
      }
      .key {
        width: 140px;
        float: left;
        color: #999;
      }
      .value {
        float: left;
        width: 650px;
      }
    }
  }
  .modifyBtn {
    margin-left: 30px;
  }
}
</style>